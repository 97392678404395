import React from "react";
import {graphql} from "gatsby";
import {OurCommitmentsQuery} from "../../../../graphql-types";
import Subpage, {Selectors} from "./subpage";

type ReactProps = {
    data: OurCommitmentsQuery
}

const Adults:React.FC<ReactProps> = ({data}) => {

    const selectors: Selectors[] = [
        {id: 1, title: data.datoCmsEngagementsNew.mainPageButtonTitle, content: data.datoCmsEngagementsNew.mainContent, selected: false, link: "/engagements"},
        {id: 2, title: data.datoCmsEngagementsNew.securiteButtonTitle, content: data.datoCmsEngagementsNew.securiteContent, selected: false, link: "/engagements/securite"},
        {id: 3, title: data.datoCmsEngagementsNew.accessibiliteButtonTitle, content: data.datoCmsEngagementsNew.accessibiliteContent, selected: false, link: "/engagements/accessibilite"},
        {id: 4, title: data.datoCmsEngagementsNew.satisfactionButtonTitle, content: data.datoCmsEngagementsNew.satisfactionContent, selected: true, link: "/engagements/satisfaction"},
        {id: 5, title: data.datoCmsEngagementsNew.tarifsButtonTitle, content: data.datoCmsEngagementsNew.tarifsContent, selected: false, link: "/engagements/tarifs"},
    ];
    const sContent = selectors[3].content;

    return (
        <Subpage
            data={data}
            selectors={selectors}
            richContent={sContent}
            seo={data.datoCmsEngagementsNew.satisfactionSeo}
            cover={data.datoCmsEngagementsNew.satisfactionPageCover[0]}
        />
    )

}

export const pageQuery = graphql`
    query Satisfaction($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }
        datoCmsEngagementsNew(locale: { eq: $locale }) {
            ...EngagementFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default Adults;